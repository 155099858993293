<template>
  <div class="finalDraftHistory">
    <div class="finalDraftHistory-header" v-if="isDownloadDrawer">
      <div class="finalDraftHistory-header_btn" @click="show = !show">
        <span>{{ $t('orderInfo.see_finalDraft_records') }}</span>
        <span style="margin-left: 8px">
          <img v-if="!show" :src="require('@/assets/image/feedbackRecords-arrow-bottom.png')" alt="" />
          <img v-else :src="require('@/assets/image/feedbackRecords-arrow-top.png')" alt="" />
        </span>
      </div>
    </div>
    <div class="finalDraftHistory-header_flex" v-else>
      <span>{{ $t('orderInfo.see_finalDraft_records') }}</span>
      <span class="finalDraftHistory-header_btn _rel" @click="show = !show">
        <img v-if="!show" :src="require('@/assets/image/feedbackRecords-arrow-bottom.png')" alt="" />
        <img v-else :src="require('@/assets/image/feedbackRecords-arrow-top.png')" alt="" />
      </span>
    </div>
    <div class="finalDraftHistory-container mt-1" v-if="show">
      <a-steps class="finalDraftHistory-records" progress-dot :current="finalDraftHistory.length" direction="vertical">
        <a-step v-for="(item, index) of finalDraftHistory" :key="index">
          <template v-slot:title>
            <div class="create-date pt-1">{{ moment(item.uploadDate).format($t('dateFormat.e')) }}</div>
          </template>
          <template v-slot:description>
            <div class="item-content mt-2">
              <div class="records-item">
                <div class="label">{{ $t('orderInfo.final_document') }}：</div>
                <div class="text">
                  <a
                    class="uline flex"
                    :href="(item.targetFileList ? item.targetFileList[0].url : null) | accelerateUrl"
                  >
                    <span class="dis-ib ellipsis" style="max-width: calc(100% - 20px)">
                      {{ item.targetFileList ? item.targetFileList[0].name : null }}
                    </span>
                    <a-icon class="pt-1 ml-2" type="download" />
                  </a>
                </div>
              </div>
              <div class="records-item">
                <div class="label">{{ $t('orderInfo.final_document_show') }}：</div>
                <div class="text">{{ item.targetFileList ? item.targetFileList[0].remark : null }}</div>
              </div>
            </div>
          </template>
        </a-step>
      </a-steps>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'FinalDraftHistory',
  props: {
    isDownloadDrawer: {
      tyep: Boolean,
      default: false,
    },
    finalDraftHistory: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      moment,
    }
  },
}
</script>

<style lang="less" scoped>
.finalDraftHistory {
  &-header_flex {
    font-weight: 400;
    line-height: 16px;
    color: #4080ff;
    .finalDraftHistory-header_btn {
      display: inline-block;
      font-weight: normal;
      color: #4080ff;
      line-height: 13px;
      margin-left: 8px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-header {
    text-align: center;
    &_btn {
      display: inline-block;
      font-size: 13px;
      font-weight: normal;
      color: #4080ff;
      line-height: 13px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &-container {
    background: rgba(245, 246, 250, 0.6);
    border-radius: 0px 0px 0px 0px;
    .finalDraftHistory-records {
      background-image: url('~@/assets/image/feedbackRecords-bg.png');
      padding: 12px;
      .create-date {
        width: 120px;
        line-height: 13px;
        font-size: 12px;
        color: #8895ab;
      }
      .item-content {
        .records-item {
          margin-bottom: 8px;
          display: flex;

          .label {
            width: 105px;
            display: inline-block;
            text-align: right;
            font-size: 13px;
            color: #46546c;
          }
          .text {
            width: calc(100% - 105px);
            color: #8895ab;
            font-size: 13px;
            a {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
::v-deep {
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #eeeeee;
  }
  .ant-steps-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: rgba(64, 128, 255, 0.2);
    box-shadow: 0px 3px 63px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
  }
  .ant-steps-icon > .ant-steps-icon-dot {
    width: 8px;
    height: 8px;
    background-color: #4080ff !important;
    opacity: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .ant-steps-item-icon {
    margin-top: 1px;
  }
  .ant-steps-item-content {
    width: auto;
  }

  .ant-steps-item-title {
    width: 120px;
  }
  .ant-steps-item-description {
    flex: 1;
    width: 94%;
  }

  .ant-steps-item-operation {
    cursor: pointer;
  }
  .ant-steps-vertical .ant-steps-item-content {
    min-height: none;
  }
}
</style>
